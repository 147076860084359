<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					헬로스테이 포인트
				</span>
			</p>
			<a class="nav__btn">
				<i class="icon icon-point--white">포인트</i>
			</a>
		</div>
	</nav>
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<div class="mypage__my">
					<div class="mypage__my-info">
						<div class="mypage__my-name">
							<span>
								<strong>인기많은만두</strong>님의<br>
								사용가능한 포인트
							</span>
						</div>
					</div>
					<div class="mypage__my-point">
						<small>
							잔여 포인트
						</small>
						<strong>3000P</strong>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="box">
					<div class="mypage__wrap">
						<div class="mypage__inner">
							<!-- 마이페이지 포인트 상세 -->
							<div class="mypage__pointdetail">
								<div class="tab tab--half">
									<a href="#" class="active">전체</a>
									<a href="#">적립내역</a>
									<a href="#">사용내역</a>
								</div>
								<!-- [D] 활성화 시 active 클래스 추가 -->
								<div class="picker">
									<button type="button" class="active">7일</button>
									<button type="button">30일</button>
									<button type="button">90일</button>
									<button type="button">180일</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textbox textbox--margin">
					<div class="textbox__icon">
						<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
					</div>
					<h3 class="textbox__title">적립/사용 가능한 <br> 포인트 내역이 없어요</h3>
					<p class="textbox__description">
						지금 헬로스테이 추천을 하면 포인트를 얻을 수 있어요
					</p>
					<div class="textbox__notice">
						내 추천인코드 : <strong class="skyblue">DADAf123F</strong>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

